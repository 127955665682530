.brand {
  color: $white;
  transition: .2s ease-in-out color;

  &:hover {
    text-decoration: none;
  }
  
  .brand__name {
    font-family: $font-base;
    text-transform: uppercase;
  }

  .brand__subname {
    color: $pink;
    font-family: $font-code;
    font-weight: 300;
  }

  &.main {
    padding-top: 120px;
    text-align: center;
  }
}

.brand.secondary {
  position: absolute;
  left: 4rem;
  top: 4rem;
  z-index: 800;

  .brand__subname {
    color: $white;
    display: flex;
    align-items: center;
    transition: .2s ease-in-out color;
  }

  .brand__dot {
    display: block;
    width: 8px;
    height: 8px;
    background: $pink;
    margin-left: 7px;
    margin-right: 10px;
    transition: .2s ease-in-out background;
  }

  &:hover {
    color: $pink;

    .brand__subname {
      color: $pink;
    }

    .brand__dot {
      background: $white;
    }
  }

  @media (max-width: 768px) {
    top: 2rem;
    left: 2rem;
  }
}

.brand.footer {
  .brand__name {
    font-size: $font-size-2;
  }

  .brand__subname {
    font-size: $font-size-1;
  }
}