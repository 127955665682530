.stripe-background {
  display: flex;
  border: 1px solid rgba($purple, .2);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &__block {
    flex: 1;
    box-shadow: -1px 0 0 0 rgba($purple, .2);
  }
}