.about-content {
  display: flex;
  flex-direction: row-reverse;

  @media (max-width: 900px) {
    flex-direction: column-reverse;
  }
}

.about-blurb {
  flex: 1;
  position: relative;
  padding: 2rem 4rem;
  align-self: center;
  border: 1px solid rgba($purple, .2);

  ul {
    margin: 0;
  }
}

.about-pic {
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;

  @media (max-width: 1130px) {
    flex: 1.5;
  }

  &:before,
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  &:before {
    left: 10px;
    top: 10px;
    border: 1px solid $pink;
  }

  &:after {
    left: -10px;
    top: -10px;
    border: 1px solid $purple;
  }

  img {
    display: block;
  }
}

.about-wrapper {
  position: relative;
  margin: 5rem auto;
  display: flex;
  flex-direction: row;

  @media (max-width: 900px) {
    flex-direction: column;
  }

  &__title {
    padding: 2rem 4rem;
    border: 1px solid rgba($purple, .2);
    flex: 1;
  }
}

.about-list {
  display: flex;
  flex-direction: column;
  border: 1px solid rgba($purple, .2);
  border-left: none;
  flex: 4;
  
  @media (max-width: 900px) {
    border: 1px solid rgba($purple, .2);
  }

  &__item {
    border-top: 1px solid rgba($purple, .2);
    padding: 2rem 4rem;
    font-size: $font-size-2;
    font-family: $font-code;

    &:first-of-type {
      border-top: none;
    }

    p {
      margin: 0;
      font-size: 1.4rem;
    }
  }
}
