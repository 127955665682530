.project-pagination {
  margin-top: 2rem;
  padding: 4rem 0;
  font-size: $font-size-2;
  border-top: 1px solid $pink;
  
  .container {
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
}

.pagination-links {
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-left: 20px;

  @media (max-width: 768px) {
    margin-left: 0;
    margin-top: 20px;
    flex-direction: column;
  }
}

.pagination-item {
  display: inline-flex;

  svg {
    font-size: inherit;
    width: 1em;
    height: 1em;
    margin: 0 5px;
  }

  &__next {
    margin-left: auto;
  }

  @media (max-width: 768px) {
    svg {
      display: none;
    }
    
    &__next {
      margin-top: 10px;
      margin-left: 0;
    }
  }
}