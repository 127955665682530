.hide {
  display: none !important;
}

.invisible {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

// Only display content to screen readers
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}