:root {
  --mouse-x: calc(100% - 130px);
  --mouse-y: 75%;
}

.project-playground {
  display: flex;
  align-items: center;
  padding-top: 160px;
  padding-bottom: 160px;
  min-height: 80vh;
  
  &--details {
    width: 340px;
    margin-right: 100px;
  }
  
  &--graphic {
    flex: 1;
    border: 1px solid $pink;
    height: 300px;
    position: relative;
  }

  @media (max-width: 900px) {
    padding-top: 80px;
    padding-bottom: 80px;
    min-height: 80vh;
    align-items: flex-start;
    flex-direction: column-reverse;

    &--details {
      margin: 20px auto;
      text-align: center;
    }

    &--graphic {
      flex: none;
      width: 100%;
      height: 250px;
      overflow: hidden;
    }
  }
}

.tilt-container-parent {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  z-index: 1;

  .tilt-container-child {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transform-style: preserve-3d;
  }

  .logo {
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

%playground {
  position: absolute;
  top: -10px;
  bottom: -10px;
  left: -20px;
  right: -20px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 900px) {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.rf-playground {
  @extend %playground;

  .logo {
    max-width: 110px;
  }

  canvas {
    position: absolute;
  }
}

.mw-playground {
  @extend %playground;

  @media (max-width: 900px) {
    overflow: hidden;
  }

  &:hover {
    
    .helix .strand {
      animation-play-state: running;
    }
  }

  .logo {
    position: absolute;
    max-width: 400px;
    z-index: 1;

    @media (max-width: 900px) {
      width: 90%;
    }
  }

  .horizon-lines {
    position: absolute;
    min-height: 100%;
  }
}

.helix {
  position: absolute;
  width: 17px;
  height: 130%;
  right: 70px;
  top: 50%;

  @media (max-width: 900px) {
    display: none;
  }
  
  .strand {
    opacity: .2;
    position: absolute;
    width: 34px;
    height: 4px;
    background: $pink;
    transform-origin: center center;
    transform: rotate(-90deg);
    box-shadow: 0 0 40px 3px rgba(#E400C7, .2);
    animation: helix-strand 3000ms linear infinite;
    animation-play-state: paused;
  }
}

@keyframes helix-strand {
  0% {
    transform: rotate(-90deg);
    opacity: .2;
  }

  25% {
    opacity: .2;
  }

  50% {
    opacity: .2;
  }
  
  75% {
    opacity: 1;
  }

  100% {
    transform: rotate(270deg);
    opacity: .2;
  }
}

@keyframes flicker {
  from {
    -webkit-filter: drop-shadow( 0 0 30px $purple);
    filter: drop-shadow( 0 0 30px $purple);
  }
  30% {
    -webkit-filter: drop-shadow( 0 0 40px $purple);
    filter: drop-shadow( 0 0 40px $purple);
  }
  to {
    -webkit-filter: drop-shadow( 0 0 20px $purple);
    filter: drop-shadow( 0 0 20px $purple);
  }
}

.ru-playground {
  @extend %playground;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .logo {
    width: 200px;
    max-width: 100%;
  }

  .csgo-logo {
    position: absolute;
    width: 400px;
    max-width: 80%;
    fill-opacity: 0;
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    transition: 1s linear stroke-dashoffset, 1s linear fill-opacity;
    z-index: 0;
    -webkit-filter: drop-shadow( 0 0 30px $purple);
    filter: drop-shadow( 0 0 30px $purple);
  }
  
  &:hover {
    .csgo-logo {
      fill-opacity: .2;
      stroke-dashoffset: 0;
      transition: 3s linear stroke-dashoffset, .2s 1.2s linear fill-opacity;
      animation: .7s linear flicker infinite alternate;
    }
  }

  .corner-pattern {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      display: block;
      background-repeat: no-repeat;
      background-size: contain;
      height: 100%;
      opacity: .5;
    }

    &:before {
      top: 0;
      background-image: url(../../uploads/rankup/preview-ru-bg-top.svg);
      background-position: right top;
    }

    &:after {
      bottom: 0;
      background-image: url(../../uploads/rankup/preview-ru-bg-bottom.svg);
      background-position: left bottom;
    }
  }
}

.pgg-playground {
  @extend %playground;
  left: 10px;
  right: 10px;

  .heart-pattern {
    display: flex;
    flex-wrap: wrap;
    font-size: 20px;

    > svg {
      flex: 1 0 6.5%;
      margin-bottom: 10px;
      margin-top: 10px;
      transition: .2s ease-in-out opacity;

      &:hover {
        opacity: 1 !important;
        transition: none;
      }

      @media (max-width: 900px) {
        &:nth-child(odd) {
          margin: 6px;
        }
  
        &:nth-child(even) {
          display: none;
        }
      }

    }
  }

  .logo {
    position: absolute;
    max-width: 375px;
    z-index: 1;

    @media (max-width: 900px) {
      width: 90%;
    }
  }
}

@keyframes true-north {
  0%, 40%, 80% {
    transform: rotate(-20deg);
  }
  33% {
    transform: rotate(-17deg);
  }
  66% {
    transform: rotate(-27deg);
  }
  100% {
    transform: rotate(-18deg);
  }
}

@keyframes kraken {
  0% {
    transform: rotate(1deg) translateX(0px) translateY(-2px) skewX(4deg);
  }
  100%{
    transform: rotate(0deg) translateX(0px) translateY(0px) skewX(0);
  }
}

.lmc-playground {
  @extend %playground;
  left: 10px;
  right: 10px;

  &:hover {
    
    .compass,
    .kraken {
      animation-play-state: running;
    }

    .kraken {
      opacity: 1;
      transition: 0.3s ease-in-out opacity,1.2s cubic-bezier(0.4, 0, 0.14, 0.58) left, 1.1s cubic-bezier(0.4, 0, 0.14, 0.58) top;
    }

    .skull {
      opacity: 0;
    }
  }

  .logo {pointer-events: none;
    position: absolute;
    max-width: 270px;
    z-index: 1;
  }

  .harbor {
    pointer-events: none;
    position: absolute;
    width: 65%;
    left: -50px;
    opacity: .8;

    @media (max-width: 900px) {
      width: auto;
    }
  }

  .compass {
    pointer-events: none;
    position: absolute;
    width: 120px;
    top: -90px;
    right: -20px;
    z-index: 1;
    opacity: .7;
    transform: rotate(-20deg);
    transform-origin: 50% 68.75%;
    animation: true-north 4s infinite ease-in-out alternate;
    animation-play-state: paused;

    @media (max-width: 1100px) {
      right: 0;
    }
  }

  .ocean-boundary {
    position: absolute;
    left: 40%;
    top: 0;
    bottom: 0;
    right: 0;
  }

  .kraken {
    pointer-events: none;
    width: 100px;
    position: absolute;
    left: var(--mouse-x);
    top: var(--mouse-y);
    transform-origin: 20% bottom;
    opacity: 0;
    animation: kraken 1.2s infinite ease-in-out alternate;
    animation-play-state: paused;
    transition: 1.2s cubic-bezier(0.4, 0, 0.14, 0.58) left, 1.1s cubic-bezier(0.4, 0, 0.14, 0.58) top;
  }

  .skull {
    pointer-events: none;
    width: 40px;
    position: absolute;
    left: var(--mouse-x);
    top: var(--mouse-y);
    opacity: .7;
  }

  @media (max-width: 900px) {
    .compass,
    .ocean-boundary {
      display: none;
    }
  }
}

.project-header {
  .rf-playground,
  .mw-playground,
  .pgg-playground,
  .lmc-playground {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .lmc-playground {
    .harbor {
      width: 45%;
      opacity: .6;
    }
    .compass {
      top: initial;
      bottom: -40px;
      right: 40px;
      animation-play-state: running;
    }
  }

  .mw-playground {
    .horizon-lines {
      width: 100%;
      max-height: 110%;
    }
    
    .helix {
      height: 80%;

      .strand {
        animation-play-state: running;
      }
    }

    @media (max-width: 900px) {
      overflow: hidden;
    }
  }

  .pgg-playground {
    .heart-pattern {
      height: 100%;
      max-width: 98vw;
    }
  }
}