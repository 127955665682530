.button {
  appearance: none;
  background: none;
  border: none;
  display: inline-block;
  color: $white;
  font-family: $font-code;
  font-size: $font-size-1;
  font-weight: 300;
  padding: 25px 40px;
  background-image: url(../assets/images/dot-grid.svg);
  background-size: initial;
  background-repeat: repeat;
  position: relative;
  transition: .1s ease color;

  span {
    width: 100%;
    height: 100%;
    position: absolute;
    pointer-events: none;
    transition: .1s ease all;

    &:before, &:after {
      content: '';
      display: block;
      position: absolute;
      border-right: 1px solid;
      border-color: inherit;
      height: 14px;
      transform: rotate(-45deg);
      transform-origin: bottom center;
      transition: .1s ease all;
    }
  }

  &:hover,
  &:active {
    color: $white;
    text-decoration: none;

    span:before, span:after {
      transform: rotate(45deg);
    }

    span.box-bottom {
      border-color: $purple;
      right: 5px;
    }
    span.box-top {
      border-color: $pink;
      left: 5px;

      &:before {
        right: 9px;
      }

      &:after {
        left: -11px;
      }
    }
  }

  &:active {
    span:before, 
    span:after {
      height: 9px;
    }

    span.box-bottom {
      bottom: 0;
      right: 0;

      &:before {
        top: -9px;
        height: 9px;
      }
    }

    span.box-top {
      &:before {
        right: 5px;
        top: -3px;
      }

      &:after {
        left: -6px;
        bottom: -6px;
      }
    }
  }

  span.box-bottom {
    border: 1px solid $pink;
    bottom: -5px;
    right: -5px;

    &:before {
      top: -14px;
      left: -1px;
    }

    &:after {
      bottom: -1px;
      right: -1px;
    }
  }
  
  span.box-top {
    border: 1px solid $purple;
    top: -5px;
    left: -5px;
    z-index: 1;

    &:before {
      top: -5px;
      right: -11px;
    }

    &:after {
      bottom: -11px;
      left: 9px;
    }
  }
}