.tooltip {
  color: currentColor;
  font-weight: inherit;
  position: relative;
  display: inline;
  
  &:hover {
    text-decoration: none;

    &:before {
      opacity: 1;
    }

    &:after {
      opacity: 0
    }
  }

  &:before {
    content: attr(data-tooltip);
    position: absolute;
    white-space: nowrap;
    font-size: 14px;
    left: 50%;
    bottom: -40px;
    transform: translate(-50%);
    padding: 10px;
    border: 1px solid;
    pointer-events: none;
    opacity: 0;
    font-style: italic;

  }

  @media (max-width: 800px ) {
    &:before {
      bottom: initial;
      top: -40px;
      left: 0;
      transform: translate(0);
    }
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    border: 1px solid $pink;
    display: block;
    top: 50%;
  }
}