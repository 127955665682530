.menu-trigger {
  position: fixed;
  top: 4rem;
  right: 4rem;
  padding: 1rem;
  z-index: 999;
  color: $pink;
  font-family: $font-code;
  font-weight: 300;

  @media (max-width: 768px) {
    top: 2rem;
    right: 2rem;
  }
}

.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: -100%;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 700;
  opacity: 0;
  pointer-events: none;
  user-select: none;

  &.active {
    opacity: 1;
    right: -2px;
    pointer-events: initial;
    user-select: initial;

    .menu-overlay__block {
      transform: translateX(0) !important;
      transition: .2s cubic-bezier(0.22, 0.65, 0.36, 1) all;

      &:nth-child(1) {
        transition-delay: .4s !important;
      }
      &:nth-child(2) {
        transition-delay: .32s !important;
      }
      &:nth-child(3) {
        transition-delay: .24s !important;
      }
      &:nth-child(4) {
        transition-delay: .16s !important;
      }
      &:nth-child(5) {
        transition-delay: .08s !important;
      }
    }

    .menu-container {
      opacity: 1;
      right: 0;
      transform: scale(1);
      transition: .2s .5s ease all;
    }
  }

  .menu-overlay__block {
    width: 10%;
    height: 100%;

    @media (max-width: 900px) {
      width: 20%;
    }

    &:nth-child(1) {
      transform: translate(500%);
      background-color: lighten($black, .5%);
      border-left: 1px solid $pink;
    }
    &:nth-child(2) {
      transform: translate(400%);
      background-color: lighten($black, .4%);
      box-shadow: -1px 0 0 0 rgba($purple, .2);
    }
    &:nth-child(3) {
      transform: translate(300%);
      background-color: lighten($black, .3%);
      box-shadow: -1px 0 0 0 rgba($purple, .2);
    }
    &:nth-child(4) {
      transform: translate(200%);
      background-color: lighten($black, .2%);
      box-shadow: -1px 0 0 0 rgba($purple, .2);
    }
    &:nth-child(5) {
      transform: translate(100%);
      background-color: lighten($black, .1%);
      box-shadow: -1px 0 0 0 rgba($purple, .2);
    }
  }
}

@keyframes block-stack {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.menu-container {
  position: absolute;
  top: 0;
  left: 50%;
  right: 0;
  bottom: 0;
  padding: 10px;
  display: flex;
  opacity: 0;
  transform: scale(1.1);
  overflow: auto;

  @media (max-width: 900px) {
    left: 0;
  }
}

.menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: auto;
}

.menu-title {
  color: $white;
  font-family: $font-code;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 6px;
  font-size: 13px;
  padding-left: 10px;
  margin-top: 30px;
}

.menu-item {
  display: inline-block;
  font-family: $font-code;
  font-size: $font-size-5;
  font-weight: 300;
  position: relative;
  margin: 10px 0;
  transition: .2s ease-in-out color;
  padding: 10px;
  transform-style: preserve-3d;
  background-image: url(../assets/images/dot-grid.svg);
  background-size: initial;
  background-repeat: repeat;
  will-change: color;
  display: none;

  @media (max-width: 900px) {
    font-size: $font-size-3;
  }

  .active & {
    display: block;
  }

  span {
    -webkit-clip-path: inset(51% 0 -10px 0);
    clip-path: inset(51% 0 -10px 0);
    display: inline-block;
    position: relative;
    transition: .2s ease-in-out transform;
    will-change: transform;
  }

  &:before {
    content: attr(data-title);
    position: absolute;
    left: 10px;
    top: 10px;
    -webkit-clip-path: inset(0 0 48.5% 0);
    clip-path: inset(0 0 48.5% 0);
    transition: .2s ease-in-out transform;
    will-change: transform;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: -20px;
    right: -20px;
    top: 51%;
    height: 2px;
    background: $pink;
    z-index: 10;
    transform: scaleX(0) translateZ(20px);
    transition: .2s ease-in-out transform;
    transform-origin: center;
    will-change: transform;
  }

  &:hover {
    color: $white;
    text-decoration: none;
    transition: .5s ease-in-out color;

    span {
      transform: skewX(-12deg) translateX(-2px) translateY(2px) ;
      transition: .5s ease-in-out transform;
    }

    &:before {
      transform: skewX(-12deg) translateX(2px) translateY(-1px);
      transition: .5s ease-in-out transform;
    }

    &:after {
      transform: scaleX(1) translateZ(10px);
      transition: .2s ease-in-out transform;
    }
  }
}