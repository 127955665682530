.content-break {
  text-align: center;
  color: $purple;
  font-size: 30px;
  font-family: $font-code;
  font-weight: 300;
  margin-top: 100px;
  margin-bottom: 100px;
  position: relative;

  &:after {
    content: url('../assets/images/dot-grid.svg');
    width: 180px;
    position: absolute;
    left: 50%;
    top: calc(50% + 5px);
    transform: translate(-50%, -50%);
    z-index: -1;
  }
}