.hi-header {
  background-color: $black;
  background-image: url(/assets/images/square-opaque.svg), url(/assets/images/square-transparent.svg);
  background-repeat: repeat;
  background-size: 85px, 90px;
  background-position: center;
  text-align: center;  
  height: 45vh;
  min-height: 45rem;
  display: flex;
  align-items: center;
  justify-content: center;
  //border-bottom: 2px solid $pink;
  position: relative;
  flex-direction: column;

  &--logo {
    max-height: 150px;
  }

  &--title {
    background: lighten($black, 5%);
    padding: 10px;
    margin-top: 10px;
  }
}

.hi-wrapper {
  position: relative;
  padding: 4rem;
  max-width: 800px;
  margin: -4rem auto 4rem;
  background: $black;
  font-size: $font-size-2;
  line-height: 2;
  color: $white;

  &:before,
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    user-select: none;
    pointer-events: none;
  }

  &:before {
    left: 10px;
    top: 10px;
    border: 1px solid $pink;
  }

  &:after {
    left: -10px;
    top: -10px;
    border: 1px solid $purple;
  }

  @include heading-tags {
    text-transform: none;
    //font-weight: 400;;
    //font-family: $font-code;
    letter-spacing: 1px;
    margin-bottom: 20px;
  }

  p, .p {
    font-size: $font-size-2;
    line-height: 2;
    margin-bottom: 2rem;
  }
}

.hi-footer {
  margin: 0 -30px -20px;
  border-top: 1px solid $purple;
  font-size: $font-size-1;
  padding: 1rem 3rem;
  display: flex;
  justify-content: space-between;
}