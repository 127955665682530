::selection {
  background-color: rgba($pink, .7);
  color: $white;
}

:focus {
  cursor: pointer;
  outline-color: $pink;
  outline-width: 2px;
  outline-style: auto;
  outline-offset: 10px;
}

*, *:after, *:before {
  box-sizing: border-box;
}

html { 
  font-size: 62.5%; 
  font-family: 'Work Sans', sans-serif;
  height: 100%;
}

body {
  font-size: 16px; 
  font-size: 1.6rem;
  color: $white;
  background-color: $black;
  background-size: 25rem;
}

a {
  text-decoration: none;
  font-weight: 400;
  color: $pink;
  word-wrap: break-word;
  font-family: $font-code;

  &:hover {
    color: darken($pink, 10%);
    cursor: pointer;
    text-decoration: line-through;
  }

  &:focus {
    color: darken($pink, 10%);
    cursor: pointer;
    outline-color: $pink;
    outline-width: 2px;
    outline-style: auto;
    outline-offset: 10px;
  }

  &:disabled, &.disabled, &[disabled] {
    opacity: .5;
    cursor: not-allowed;

    &:hover {
      color: darken($pink, 10%);
    }
  }
}

hr {
  display: flex;
  width: 100%;
  justify-content: center;
  align-content: center;
  border: none;
  margin: 3rem 0;
  
  &:after {
    content: '';
    display: inline-block;
    border: 1px solid;
    width: 6px;
    height: 6px;
    box-shadow: 20px 0, -20px 0;
    color: $black;
    background: $white;
  }
}

img {
  max-width: 100%;
}