.project-wrapper {
  background: $white;
  color: #202f57;
}

.project-header {
  background: $black;
  text-align: center;  
  height: 65vh;
  min-height: 45rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid $pink;
  position: relative;
}

.project-logo {
  max-width: 30rem;
  max-height: 20rem;
  margin: auto;
}

.project-title {
  text-transform: uppercase;
}

.project-subheader {
  font-family: $font-code;
  font-weight: 300;
  color: $pink;
  font-size: 1.9rem;
  margin-bottom: 4rem;
}

.project-container,
.project-meta {
  display: flex;
  flex-direction: column;
  max-width: 740px;
  margin: auto;

  @media (max-width: 1170px) {
    max-width: 100%;
  }

  img {
    margin: 1rem 0;
  }

  h3, .h3 {
    margin-top: 30px;
  }

  > h3,
  > h4, 
  > h5,
  > h6 {
    text-transform: none;
    margin-top: 1.5rem;
    letter-spacing: 0px;
    font-weight: 600;
  }

  video {
    width: 100%;
  }

  .content-break {
    margin-top: 5rem;
    margin-bottom: 5rem;
    font-size: $font-size-3;
    z-index: 10;
    &:after {
      opacity: .2;
    }
  }
}

.project-meta {
  padding: 5rem 0 2rem;
}

.project-info {
  flex: 1;
  margin-bottom: 2rem;
}

.project-details {
  display: flex;
  flex: 1;
}

.project-details__column {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.project-details__title {
  text-transform: none;
  letter-spacing: 0;
  margin-bottom: 10px;
  color: $black;
  font-weight: 600;

  ~ .project-details__title {
    margin-top: 2rem;
  }
}

.project-details__item {
  margin-bottom: 1rem;
}

.project-media {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  margin: 0 -210px;

  .project-media__item {
    flex: 1;
    padding: 1rem;
  }

  @media (max-width: 1170px) {
    margin: 10px auto;
  }

  @media (max-width: 850px) {
    flex-direction: column;
  }
}