/* * * *
 * Screen Sizes
 * Used in _mixins.scss > @mixin respond-to($breakpoint)
 * * * */
$breakpoints: (

  'xxs': (max-width: 480px),

  'xs': (max-width: 767px),

  'sm': "(min-width: 768px) and (max-width: 991px)",
  'sm-up': (min-width: 768px),
  'sm-down': (max-width: 991px),

  'md': "(min-width: 992px) and (max-width: 1180px)",
  'md-up': (min-width: 992px),
  'md-down': (max-width: 1180px),

  'lg': (min-width: 1181px),
);

/* * * *
 * Fonts
 * * * */
$font-base: 'Work Sans', sans-serif;
$font-code: 'Source Code Pro', monospace;

$font-size-1: 1.7rem;
$font-size-2: 1.9rem;
$font-size-3: 2.2rem;
$font-size-4: 2.4rem;
$font-size-5: 2.6rem;

/* * * *
 * Interaction
 * * * */
$focus-state: 0 0 0 3px rgba(lighten($pink, 10%), .4);