.form {
  max-width: 600px;
  width: 100%;
  margin: 3rem auto;
  padding: 4rem;
  display: flex;
  flex-direction: column;
  position: relative;

  .button {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  font-family: $font-code;
  font-weight: 300;
}

input, 
textarea {
  margin: 10px 0;
  border: 1px solid $purple;
  padding: 14px;
  background: $black;
  position: relative;
  box-shadow: 4px 4px 0 $black, 5px 5px 0 $pink;
  color: $white;
  font-size: 1.4rem;
}

input {
  height: 5rem;
}

textarea {
  height: 12rem;
}

.pristine-error {
  font-size: 1.2rem;
}