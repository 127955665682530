footer {
  padding: 4rem 0;
  font-size: 1.4rem;
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.footer-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  + .footer-section {
    margin-left: auto;
    flex: none;
  }

  @media (max-width: 768px) {
    margin: 20px auto;
    text-align: center;
  }
}

.footer-nav,
.footer-rights {
  display: flex;
  font-family: $font-code;

  @media (max-width: 768px) {
    justify-content: center;
  }
}

.footer-nav__item + .footer-nav__item {
  margin-left: 20px;
}