@mixin heading-tags {
  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    @content;
  }
}

@include heading-tags {
  line-height: 1.25;
  color: inherit;
  font-weight: 800;
  margin: 0;
  text-transform: uppercase;
}

h1, .h1 {
  font-size: $font-size-5;
  letter-spacing: 10px;
}

h2, .h2 {
  font-size: $font-size-4;
  letter-spacing: 10px;
}

h3, .h3 {
  font-size: $font-size-3;
  letter-spacing: 10px;
}

h4, .h4 {
  font-size: $font-size-2;
  letter-spacing: 10px;
}

h5, .h5 {
  font-size: $font-size-1;
  letter-spacing: 3px;
}

h6, .h6 {
  font-size: 14px;
  letter-spacing: 3px;
}

p, .p {
  font-size: $font-size-1;
  font-weight: 400;
  line-height: 1.7;
  margin-top: .9rem;
  margin-bottom: .9rem;
}

ul {
  font-size: inherit;
  padding-left: 0;
  margin: 0;
  list-style-type: none;
  margin-bottom: 2rem;

  li {
    margin: 1rem 0;
    position: relative;
    padding-left: 2rem;
    line-height: 1.6;

    &:before {
      content: '\2022';
      color: currentColor;
      font-size: 1.4rem;
      padding-right: 1rem;
      position: absolute;
      top: 2px;
      left: 0;
    }
  }

  &.checklist li:before {
    content: '✓';
    color: $pink;
  }
}

ol {
  font-size: inherit;
  color: $white;
  padding-left: 0;
  margin: 0;
  list-style-type: none;
  margin-bottom: 2rem;
  counter-reset: ol-counter;

  li {
    margin: 1rem 0;
    position: relative;
    padding-left: 2.5rem;
    line-height: 1.6;

    &:before {
      content: counter(ol-counter)':';
      counter-increment: ol-counter;
      color: rgba($white, .5);
      font-size: 1.4rem;
      padding-right: 1.5rem;
      position: absolute;
      top: 2px;
      left: 0;
    }
  }
}

code, kbd, pre, samp {
  font-family: $font-code;
}

code {
  background: rgba($black, .1);
  padding: 2px 10px;
  margin: 0 2px;
  color: $pink;
  font-size: 1.4rem;
}

.font-size-small {
  font-size: 1.3rem;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}
