header {
  position: relative;
}

.page-header {
  text-align: center;
  padding-top: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}